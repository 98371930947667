import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/index1",
    name: "index1",
    component: () => import("../views/index1.vue"),
  },
  {
    path: "/4kIndex",
    name: "4kIndex",
    component: () => import("../views/4kIndex.vue"),
  },
  {
    path: "/luckdraw",
    name: "luckdraw",
    component: () => import("../views/luckdraw/index.vue"),
  },
  {
    path: "/luckdraw_ver",
    name: "luckdraw_ver",
    component: () => import("../views/luckdraw/index_ver.vue"),
  },
  {
    path: "/luckdraw_tra",
    name: "luckdraw_tra",
    component: () => import("../views/luckdraw/index_tra.vue"),
  },
  {
    path: "/merchant",
    name: "merchant",
    component: () => import("../views/merchant/index.vue"),
  },
  {
    path: "/integretedService",
    name: "integretedService",
    component: () => import("../views/integretedService/index.vue"),
  },
  {
    path: "/integretedService2",
    name: "integretedService2",
    component: () => import("../views/integretedService/index2.vue"),
  },
  {
    path: "/newScreen",
    name: "newScreen",
    component: () => import("../views/newScreen/index.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
