import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dataV from "@jiaminghi/data-view";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(dataV);
Vue.use(ElementUI);
// 按需引入vue-awesome图标
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons/chart-bar.js";
import "vue-awesome/icons/chart-area.js";
import "vue-awesome/icons/chart-pie.js";
import "vue-awesome/icons/chart-line.js";
import "vue-awesome/icons/align-left.js";

import VueLuckyCanvas from "@lucky-canvas/vue";
Vue.use(VueLuckyCanvas);

// 全局注册图标
Vue.component("icon", Icon);

// 适配flex
import "@/common/flexible.js";

// 引入全局css
import "./assets/scss/style.scss";

//引入播放器
import Video from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = Video;
import VideoPlayer from "vue-video-player";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import "echarts-gl";

Vue.use(VideoPlayer);

//引入轮播组件
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
Vue.use(VueAwesomeSwiper);
// import "swiper/swiper-bundle.css";
// 导入腾讯地图，获取用户当前位置
import { VueJsonp } from "vue-jsonp";
Vue.use(VueJsonp);

//引入echart
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;

import { detectZoom } from "@/utils/detectZoom.js";

//长兴市场4k电视兼容
if (window.android) {
  var qs = JSON.parse(window.android.getBindInfo());
  const uuid = qs["uuid"];

  if (uuid == "676f9d0a-4c1a-3067-941e-fb3c5c8d6118") {
    const m = detectZoom();
    //判断屏幕是否为4k
    // if (window.screen.width * window.devicePixelRatio >= 3840) {
    document.body.style.zoom = 100 / Number(m);
    // }
  }
} else {
  // const m = detectZoom();
  // document.body.style.zoom = 100 / (Number(m) / 2);
}

// const m = detectZoom();
// //判断屏幕是否为4k
// if (window.screen.width * window.devicePixelRatio >= 3840) {
//   document.body.style.zoom = 100 / (Number(m) / 2);
// } else {
//   document.body.style.zoom = 100 / Number(m);
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
